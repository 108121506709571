import axios from 'axios'
import { Client, ClientConfig, Expr, query as q } from 'faunadb'
import { AccessProvider, AccessProviderRef, DatabaseRef } from '../../types/faunadbTypes'
import { mountIndexFields } from '../../utils/dbIndex'
import { logAxiosError, logFaunaError } from '../../utils/log-helper'
import SessionCookie from '../auth/session/cookie'
import getRegion, { Region, Regions } from '../auth/session/regions'
import { getKeyNameOrId, Key } from '../keyResource'
import { isBuiltInRole, RoleInput, RoleResource } from '../roles'

const {
  REACT_APP_DB_URL: DB_URL,
  REACT_APP_PAGE_SIZE: PAGE_SIZE = 1000,
  REACT_APP_DEFAULT_REGION_GROUP: DEFAULT_REGION_GROUP = 'global'
} = process.env
const paginateOptions = { size: PAGE_SIZE }
const IS_LOCAL = process.env.REACT_APP_IS_LOCAL === 'true'

export let client: Client

export enum RequestStatus {
  idle,
  loading,
  error
}

type ApiConfig = ClientConfig & {
  urlString: string
}

let apiConfig: ApiConfig

export const connect = (config: ApiConfig): void => {
  apiConfig = config
  client = createClient(apiConfig)
}

export const connectTo = (
  databasePath: string,
  regionName: string,
  {
    secret,
    role,
    authDoc,
    ...overrideConfig
  }: Partial<ClientConfig> & GetDatabaseSecretByPathAuthOpts = {}
): Client => {
  return createClient({
    ...apiConfig,
    secret:
      secret ??
      getDatabaseSecretByPath(databasePath, regionName, {
        role,
        authDoc
      }),
    headers: {
      'X-Fauna-Source': 'Console'
    },
    ...overrideConfig
  })
}

export const createClient = (config: ApiConfig): Client => {
  const { urlString = (currentRegion?.url && DB_URL) ?? DB_URL, ...clientConfig } = config
  const url = new URL(IS_LOCAL && DB_URL ? DB_URL : urlString)

  return new Client({
    scheme: url.protocol.replace(':', '') as 'http' | 'https',
    domain: url.hostname,
    ...(url.port && {
      port: Number(url.port)
    }),
    ...clientConfig
  })
}

export const check = (): Promise<any> =>
  client.query(q.Sum([1, 1])).catch(e => {
    logFaunaError(e)
    throw e
  })

export const status = (): Promise<any> => {
  const region = defaultRegion()
  const url = IS_LOCAL ? DB_URL : region.url
  return axios.get(`${url}/ping`).catch(e => {
    logAxiosError(e)
    throw e
  })
}

export const database = (name: string): Promise<Record<string, any>> => {
  return client.query(q.Get(q.Database(name))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export type DatabasesDataResponse = DatabaseRef[]

export type DatabasesDataResponseWithRegionGroup = {
  regionPrefix: string
  regionName: string
  db: DatabaseRef
}

export let currentRegion: Region

export function setCurrentRegion(region: Region) {
  currentRegion = region
  const regionConfig: ApiConfig = { secret: region.secret, urlString: region.url }
  apiConfig = regionConfig
}

export function getCurrentRegion() {
  return currentRegion
}

export const AllDatabases = (regions: Regions): Promise<DatabasesDataResponseWithRegionGroup[]> => {
  const promises = Object.entries(regions).map(entry => selectDatabaseFromRegionGroup(entry[1]))
  return Promise.all(promises).then(results =>
    results.flat().sort((a, b) => a.db.id.localeCompare(b.db.id))
  )
}

function selectDatabaseFromRegionGroup(
  region: Region
): Promise<DatabasesDataResponseWithRegionGroup[]> {
  const regionConfig: ApiConfig = { secret: region.secret, urlString: region.url }
  const regionClient = createClient(regionConfig)
  return regionClient
    .query<{ data: DatabasesDataResponse }>(q.Paginate(q.Databases(), paginateOptions))
    .then(response => {
      const dbs = response.data.map(db => ({
        regionPrefix: region.regionPrefix,
        regionName: region.regionName,
        db: db
      }))
      // @ts-ignore
      return dbs.sort((a, b) => a.db.value.id.localeCompare(b.db.value.id))
    })
    .catch(err => {
      logFaunaError(err, `Error fetching databases for ${region.regionName}`)
      return []
    })
}

export const databases = (): Promise<DatabasesDataResponse> => {
  return client
    .query<{ data: DatabasesDataResponse }>(q.Paginate(q.Databases(), paginateOptions))
    .then(response => {
      // @ts-ignore
      return response.data.sort((a, b) => a.value.id.localeCompare(b.value.id))
    })
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const classes = (): Promise<Record<string, any>[]> => {
  return client
    .query<{ data: Record<string, any>[] }>(
      q.Map(
        q.Paginate(q.Collections(), paginateOptions),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(response => response.data)
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const indexes = (): Promise<Record<string, any>[]> => {
  return client
    .query<{ data: Record<string, any>[] }>(
      q.Map(
        q.Paginate(q.Indexes(), paginateOptions),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(response => response.data)
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const functions = (): Promise<Record<string, any>[]> => {
  return client
    .query<{ data: Record<string, any>[] }>(
      q.Map(
        q.Paginate(q.Functions(), paginateOptions),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(response => {
      return response.data.sort((a, b) => a.name.localeCompare(b.name))
    })
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const createFunction = (data: {
  name: string
  body: Record<string, any>
}): Promise<Record<string, any>[] | object> => {
  return client.query(q.CreateFunction(data)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const updateFunction = (
  id: string,
  data: {
    name: string
    body: Record<string, any>
  }
): Promise<Record<string, any>[] | object> => {
  return client.query(q.Update(q.Function(id), data)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const deleteFunction = (id: string): Promise<Record<string, any>[] | object> => {
  return client.query(q.Delete(q.Function(id))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const getFunction = (id: string): Promise<Record<string, any> | object> => {
  return client.query(q.Get(q.Function(id))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

function ensureDataProp(response) {
  return response.data.map(item => {
    const isArray = Array.isArray(item)
    const isObjectWithoutData = typeof item === 'object' && !item.data

    if (!isArray && isObjectWithoutData) {
      item.data = {}
    }

    return item
  })
}

export const all = (index: string): Promise<Record<string, any>[]> => {
  return client
    .query(
      q.Map(
        q.Paginate(q.Match(q.Index(index)), { size: 100 }),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(ensureDataProp)
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const documentsByCollection = ({ collectionName, after, pageSize = 50 }) => {
  return client
    .query(
      q.Map(
        q.Paginate(q.Documents(q.Collection(collectionName)), {
          size: pageSize,
          ...(after != null && { after })
        }),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(
      response =>
        ({
          ...response,
          data: ensureDataProp(response)
        } as any)
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const documentsByTerms = (
  index: string,
  terms: string | string[]
): Promise<Record<string, any>[]> => {
  return client
    .query(
      q.Map(q.Paginate(q.Match(q.Index(index), terms), { size: 100 }), value =>
        q.If(q.IsRef(value), q.Get(value), value)
      )
    )
    .then(ensureDataProp)
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const documentByRef = (className: string, referenceId: string): Promise<Expr> => {
  return client
    .query(
      q.Map(
        q.Paginate(q.Ref(q.Class(className), referenceId), paginateOptions),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(ensureDataProp)
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const selectDatabase = (path: string | null | undefined, region?: Region): void => {
  const secret = getDatabaseSecret(path, region)
  const urlString = region ? region.url : currentRegion.url
  connect({ urlString, secret })
}

export const getDatabaseSecret = (databasePath: string, region?: Region) => {
  const rootSecret = (region || currentRegion).secret.split(':')[0]
  return databasePath ? `${rootSecret}:${databasePath}:${'admin'}` : rootSecret
}

type CreateDatabaseParams = {
  name: string
  data?: string
  region: Region
  parent?: string
}
export type UpdateDatabaseParams = {
  originalName: string
  regionGroup: string
  name: string
  path: string
  data: string | null | undefined
}
type RemoveDatabaseParams = {
  name: string
}

export const createDatabase = (params: CreateDatabaseParams): Promise<Record<string, any>> => {
  const { region, parent, ...createParams } = params
  const rootSecret = region.secret.split(':')[0]
  const secret = parent ? `${rootSecret}:${parent}:${'admin'}` : rootSecret
  const regionConfig: ApiConfig = { secret: secret, urlString: region.url }
  const regionClient = createClient(regionConfig)
  return regionClient.query(q.CreateDatabase(createParams)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const updateDatabase = ({
  originalName,
  name,
  data
}: UpdateDatabaseParams): Promise<Record<string, any>> => {
  return client.query(q.Update(q.Database(originalName), { name, data })).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const removeDatabase = ({
  name
}: RemoveDatabaseParams): Promise<Record<string, any>[] | object> => {
  return client.query(q.Delete(q.Database(name))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

type CreateClassParams = {
  name: string
  history_days: number
  ttl_days: number
  data: {}
}
type UpdateClassParams = {
  originalName: string
  name: string
  history_days: number
  ttl_days: number
  data: {}
}
type RemoveClassParams = {
  name: string
}

export const createClass = (params: CreateClassParams): Record<string, any> => {
  return client.query(q.CreateClass(params)).catch(e => {
    logFaunaError(e)
    throw e
  })
}
export const updateClass = ({
  originalName,
  name,
  history_days,
  ttl_days
}: UpdateClassParams): Record<string, any> => {
  return client
    .query(q.Update(q.Class(originalName), { name, history_days, ttl_days }))
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}
export const removeClass = ({
  name
}: RemoveClassParams): Promise<Record<string, any>[] | object> => {
  return client.query(q.Delete(q.Class(name))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

type IndexParams = {
  name: string
  source: string
  terms: string[]
  values: string[]
  unique: boolean
  serialized: boolean
}

export const createIndex = (params: IndexParams): Record<string, any> => {
  return client
    .query(
      q.CreateIndex({
        ...params,
        source: q.Class(params.source),
        terms: mountIndexFields(params.terms),
        values: mountIndexFields(params.values)
      })
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const updateIndex = (indexRef: Record<string, any>, params: IndexParams) => {
  return client
    .query(
      q.Update(indexRef, {
        name: params.name,
        unique: params.unique,
        serialized: params.serialized
      })
    )
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const removeIndex = (indexRef: Record<string, any>) => {
  return client.query(q.Delete(indexRef)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const createDocument = (
  classId: string,
  data: Record<string, any>,
  credentials?: { password: string }
) => {
  return client
    .query(q.Create(q.Class(classId), { data, ...(credentials && { credentials }) }))
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const removeDocument = (classId: string, referenceId: string) => {
  return client.query(q.Delete(q.Ref(q.Class(classId), referenceId))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const updateDocument = (classId: string, referenceId: string, data: Record<string, any>) => {
  return client.query(q.Replace(q.Ref(q.Class(classId), referenceId), { data })).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const keys = () => {
  return client
    .query<{ data: Key[] }>(
      q.Map(
        q.Paginate(q.Keys(), paginateOptions),
        q.Lambda(x => q.Get(x))
      )
    )
    .then(response => {
      return response.data.sort((a, b) => {
        const aNameOrRef = getKeyNameOrId(a)
        const bNameOrRef = getKeyNameOrId(b)
        return aNameOrRef.localeCompare(bNameOrRef)
      })
    })
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const removeKey = (keyRef: Record<string, any>) => {
  return client.query(q.Delete(keyRef)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

type CreateKeyParams = {
  name: string
}

export type CreateKeyDataResponse = {
  name?: string
  database: {
    value: {
      id: string
    }
  }
  data?: {
    name?: string
  }
  role: string
  ref: {
    value: {
      id: string
    }
  }
}

export const createKey = (params: CreateKeyParams): Promise<CreateKeyDataResponse | object> => {
  return client.query(q.CreateKey(params)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const rootSecret = (): string => {
  return defaultKey()
}

export type GetDatabaseSecretByPathAuthOpts = {
  role?: string
  authDoc?: {
    collection: string
    id: string
  }
}

export const getDatabaseSecretByPath = (
  database: string,
  regionName: string,
  { authDoc, role = 'admin' }: GetDatabaseSecretByPathAuthOpts = {}
): string => {
  const secret = regionKey(regionName)
  const baseKey = `${secret}:${database}`

  if (authDoc) {
    return `${baseKey}:@doc/${authDoc.collection}/${authDoc.id}`
  }

  return `${baseKey}:${isBuiltInRole(role) ? role : `@role/${role}`}`
}

export const defaultRegion = () => {
  return getRegion(DEFAULT_REGION_GROUP)
}

export const defaultKey = () => {
  const session = SessionCookie.get()

  if (session?.data?.secret !== undefined) return session.data.secret

  return regionKey(DEFAULT_REGION_GROUP)
}

export const regionKey = (regionName = DEFAULT_REGION_GROUP) => {
  return getRegion(regionName).key
}

export const bearerToken = (regionName = DEFAULT_REGION_GROUP) => {
  return 'Bearer ' + getRegion(regionName).key
}

export type RolesDataResponse = {
  id: string
  value: {
    name: string
    id: string
  }
}[]

export const roles = async () => {
  return client
    .query<{ data: RolesDataResponse }>(q.Paginate(q.Roles(), paginateOptions))
    .then(response => {
      return response.data.sort((a, b) => a.value.id.localeCompare(b.value.id))
    })
    .catch(e => {
      logFaunaError(e)
      throw e
    })
}

export const createRole = (role: RoleInput): Promise<any> => {
  return client.query(q.CreateRole(role)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const role = (roleId: string): Promise<RoleResource | object> => {
  return client.query(q.Get(q.Role(roleId))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const updateRole = (roleId: string, updatedRole: RoleInput) => {
  return client.query(q.Update(q.Role(roleId), updatedRole)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const deleteRole = (roleId: string) => {
  return client.query(q.Delete(q.Role(roleId))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

// PROVIDERS

export const providers = (): Promise<AccessProviderRef[]> => {
  return client
    .query<{ data }>(q.Paginate(q.AccessProviders(), paginateOptions))
    .then(response => {
      return response.data.sort((a, b) => {
        return a.id.localeCompare(b.id)
      })
    })
    .catch(err => {
      logFaunaError(err, 'Error fetching AccessProviders')
      return err
    })
}

export const createAccessProvider = (
  params: AccessProvider
): Promise<AccessProviderRef | object> => {
  return client.query(q.CreateAccessProvider(params)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const updateAccessProvider = (
  id: string,
  data: AccessProvider
): Promise<AccessProvider | object> => {
  return client.query(q.Update(q.AccessProvider(id), data)).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const getAccessProvider = (id: string): Promise<AccessProvider | object> => {
  return client.query(q.Get(q.AccessProvider(id))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

export const deleteAccessProvider = (id: string): Promise<AccessProvider | object> => {
  return client.query(q.Delete(q.AccessProvider(id))).catch(e => {
    logFaunaError(e)
    throw e
  })
}

import { LoginResponse } from '../auth'
import { Region } from '../auth/session/regions'
import { Session } from '../auth/session'

export type SessionCookieContents = LoginResponse & { provider?: string }

export const SESSION_STORAGE_NAME = 'session'

export const get = (): SessionCookieContents | undefined => {
  const stored = localStorage.getItem(SESSION_STORAGE_NAME)
  if (stored) {
    return JSON.parse(stored)
  }
  return undefined
}

export const remove = (): void => {
  localStorage.removeItem(SESSION_STORAGE_NAME)
}

export const save = (contents: SessionCookieContents): void => {
  // Make sure old region info is cleared before saving a new session
  Region.clear()

  // Save new session
  localStorage.setItem(SESSION_STORAGE_NAME, JSON.stringify(contents))
}

export const mergeSessionData = (data: Partial<Session>): void => {
  const session = get()
  if (session) {
    save({ ...session, data: { ...session.data, ...data } })
  }
}

export const exists = (): boolean => {
  return !!get()
}

import cn from 'classnames'
import { useGlobalFormik } from './../../../utils/useGlobalFormik'
import React, { useContext, useMemo } from 'react'
import * as Yup from 'yup'
import { GlobalStateContext } from '../../../global-state'
import {
  Snapshot,
  BackupConfiguration,
  DEFAULT_BACKUP_CONFIGURATION
} from '../../../modules/api/frontdoor/backup'
import useSnapshots from '../../../modules/api/useSnapshots'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'

import { tx } from '../../../modules/translate'
import Button from '../../shared/Button'
import Icon from '../../shared/Icon'
import IconButton from '../../shared/IconButton'
import { Select } from '../../shared/Select'
import SnapshotTable from '../../shared/SnapshotsTable'
import DashboardTooltip from '../functions/DashboardTooltip'
import { makeOptions, CUSTOM_FOOTPRINT, RegionSelect } from './RegionSelect'
import { Region } from '../../../modules/auth/session/regions'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import capitalize from 'lodash/capitalize'
import { FloraComponents } from 'frontend-components'
import { ScheduleCategory, databaseBackupScheduleOptions } from './common'
import { SessionCookieContents } from '../../../modules/cookies/sessionStorage'
import SessionCookie from '../../../modules/auth/session/cookie'

const { Switch, Input, validateInput, FormRow, Text } = FloraComponents

export type DatabaseValues = {
  name: string
  regionGroup?: string
  databasePath?: string
  backupConfiguration?: BackupConfiguration
}

export type FormValues = DatabaseValues & {
  demoDataIsEnabled?: boolean
}

export type DatabaseFormProps = {
  loading: boolean
  onCancel: () => void
  onSubmit: (values: DatabaseValues, demoDataIsEnabled?: boolean, regionGroup?: string) => void
  submitLabel?: string
  dbName?: string
  region?: string
  databasePath?: string
  onDeleteClick?: Function
  isEdit?: boolean
  isCreate?: boolean
  onRestore?: (snapshot: Snapshot) => void
  onCopy?: (snapshot: Snapshot) => void
  backupConfiguration?: BackupConfiguration
  showBackupSettingsOnly?: boolean
}

export default function DatabaseForm({
  loading,
  onCancel,
  onSubmit,
  onRestore,
  onCopy,
  dbName = '',
  region,
  onDeleteClick,
  isEdit,
  isCreate,
  backupConfiguration,
  databasePath,
  showBackupSettingsOnly = false
}: DatabaseFormProps) {
  const {
    rolePermission: backupRestoreRolePermission,
    planPermission: backupRestorePlanPermission
  } = usePermissions(PermissionDomain.BACKUP_RESTORE, PermissionLevel.write)

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptablePlans: string[]
  if (v2Released) {
    acceptablePlans = ['Startup', 'Trial', 'Pro', 'Enterprise']
  } else {
    acceptablePlans = ['team', 'business']
  }

  const { options, regionGroup } = useMemo(() => {
    const regions = Object.values(Region.all)
    const options = makeOptions(regions)
    const searched = region || null
    const regionGroup =
      (searched &&
        options.flatMap(({ options }) => options).find(({ value }) => value === searched)) ||
      null

    return {
      options,
      regionGroup
    }
  }, [region])

  const globalState = useContext(GlobalStateContext)
  const drawerVisibilityValues = Object.values(globalState.drawerVisibility)
  const resetSnapshotPage = useMemo(() => {
    return drawerVisibilityValues.some(val => val)
  }, [drawerVisibilityValues])

  const retentionOptions = [
    { label: '5 days', value: '5' },
    { label: '15 days', value: '15' },
    { label: '1 month', value: '30' },
    { label: '3 months', value: '90' },
    { label: '6 months', value: '180' },
    { label: '1 year', value: '365' },
    { label: 'Indefinitely', value: '365000' }
  ]

  const weekdays = [
    { label: 'Monday', value: '0 0 * * 1' },
    { label: 'Tuesday', value: '0 0 * * 2' },
    { label: 'Wednesday', value: '0 0 * * 3' },
    { label: 'Thursday', value: '0 0 * * 4' },
    { label: 'Friday', value: '0 0 * * 5' },
    { label: 'Saturday', value: '0 0 * * 6' },
    { label: 'Sunday', value: '0 0 * * 7' }
  ]

  const monthdate = [
    { label: '1', value: '0 0 1 * *' },
    { label: '2', value: '0 0 2 * *' },
    { label: '3', value: '0 0 3 * *' },
    { label: '4', value: '0 0 4 * *' },
    { label: '5', value: '0 0 5 * *' },
    { label: '6', value: '0 0 6 * *' },
    { label: '7', value: '0 0 7 * *' },
    { label: '8', value: '0 0 8 * *' },
    { label: '9', value: '0 0 9 * *' },
    { label: '10', value: '0 0 10 * *' },
    { label: '11', value: '0 0 11 * *' },
    { label: '12', value: '0 0 12 * *' },
    { label: '13', value: '0 0 13 * *' },
    { label: '14', value: '0 0 14 * *' },
    { label: '15', value: '0 0 15 * *' },
    { label: '16', value: '0 0 16 * *' },
    { label: '17', value: '0 0 17 * *' },
    { label: '18', value: '0 0 18 * *' },
    { label: '19', value: '0 0 19 * *' },
    { label: '20', value: '0 0 20 * *' },
    { label: '21', value: '0 0 21 * *' },
    { label: '22', value: '0 0 22 * *' },
    { label: '23', value: '0 0 23 * *' },
    { label: '24', value: '0 0 24 * *' },
    { label: '25', value: '0 0 25 * *' },
    { label: '26', value: '0 0 26 * *' },
    { label: '27', value: '0 0 27 * *' },
    { label: '28', value: '0 0 28 * *' },
    { label: 'Last day of the month', value: '0 0 L * *' }
  ]

  const { values, handleChange, setFieldValue, handleSubmit, errors, touched } = useGlobalFormik({
    enableReinitialize: true,
    initialValues: {
      name: dbName,
      regionGroup,
      demoDataIsEnabled: false,
      backupConfigurationControls: {
        ts: backupConfiguration?.ts,
        enabled: isCreate
          ? backupRestoreRolePermission && backupRestorePlanPermission
          : backupConfiguration?.enabled || false,
        ttl_days: backupConfiguration?.ttl_days || DEFAULT_BACKUP_CONFIGURATION.ttl_days,
        frequency: backupConfiguration?.frequency || DEFAULT_BACKUP_CONFIGURATION.frequency,
        category: backupConfiguration?.category || DEFAULT_BACKUP_CONFIGURATION.category
      }
    },
    validationSchema: Yup.object({
      name: Yup.string(),
      ...(!isEdit && {
        regionGroup: Yup.mixed()
          .nullable()
          .required()
      })
    }),
    onSubmit: values =>
      onSubmit(
        {
          name: values.name,
          ...(values.regionGroup && {
            path: databasePath,
            regionGroup: values.regionGroup.value,
            backupConfigurationControls: values.backupConfigurationControls,
            customFootprintReason: 'Requesting information about custom Fauna footprint'
          })
        },
        values.demoDataIsEnabled,
        values.regionGroup?.value
      )
  })

  const isRegionSelectDisabled = Boolean(isEdit || region)
  const showSelectError = Boolean(errors.regionGroup && touched.regionGroup)
  const isRequestingCustom = values.regionGroup?.value === CUSTOM_FOOTPRINT
  const snapshots = useSnapshots({
    path: databasePath,
    region: region
  })
  const doSnapshotsExist = snapshots['list'].length > 0
  // TODO: do something with useSnapshots.error

  const defaultOption = {
    value: tx('backup.schedule.daily.label'),
    label: tx('backup.schedule.daily.label'),
    defaultCronExpression: tx('backup.schedule.daily.default_cron_expression'),
    category: ScheduleCategory.DAILY
  }

  const backupScheduleOptions = [
    {
      options: [
        defaultOption,
        {
          value: tx('backup.schedule.weekly.label'),
          label: tx('backup.schedule.weekly.label'),
          defaultCronExpression: tx('backup.schedule.weekly.default_cron_expression'),
          category: ScheduleCategory.WEEKLY
        },
        {
          value: tx('backup.schedule.monthly.label'),
          label: tx('backup.schedule.monthly.label'),
          defaultCronExpression: tx('backup.schedule.monthly.default_cron_expression'),
          category: ScheduleCategory.MONTHLY
        }
      ],
      index: 0
    }
  ]

  const findBackupScheduleOption = (category: ScheduleCategory) => {
    const databaseBackupScheduleOption = databaseBackupScheduleOptions.find(
      item => item.category.valueOf() === category.valueOf()
    )
    if (!databaseBackupScheduleOption) {
      // When invalid value is entered by the end-user,
      // backup schedule category should remain to its existing state
      // Necessary validation errors will be shown by the  `BackupsCronExpressionInput` validators
      console.log('Unsupported option %s for the backup frequency.', category.valueOf())
      //databaseBackupScheduleOption = backupSchedule
    }
    setFieldValue('backupConfigurationControls.category', databaseBackupScheduleOption.category)
    setFieldValue('backupConfigurationControls.frequency', databaseBackupScheduleOption.frequency)
    return databaseBackupScheduleOption
  }

  const isDayOfMonthFieldEnabled = () => {
    return (
      values.backupConfigurationControls.category.valueOf() === ScheduleCategory.MONTHLY.valueOf()
    )
  }
  const isDayOfWeekFieldEnabled = () => {
    return (
      values.backupConfigurationControls.category.valueOf() === ScheduleCategory.WEEKLY.valueOf()
    )
  }

  const setCronFrequency = (cron: string) => {
    setFieldValue('backupConfigurationControls.frequency', cron)
  }

  const renderBackupScheduleCategory = (isDisabled: boolean) => {
    return (
      <div className="form-group">
        <div className="form-label--tooltip">
          <label htmlFor="backupScheduleCategory">Backup Schedule</label>
          <DashboardTooltip
            id="backupScheduleCategory"
            placement="left"
            contentText={<div>Backups can be scheduled daily, weekly & monthly.</div>}
          />
        </div>
        <Select
          options={backupScheduleOptions}
          value={backupScheduleOptions[0].options.find(
            item => item.category === values.backupConfigurationControls.category
          )}
          isDisabled={isDisabled}
          onChange={(option: any) => {
            const { category } = option as { category: ScheduleCategory }
            findBackupScheduleOption(category)
          }}
        />
      </div>
    )
  }

  const renderBackupScheduleWeekday = (isDisabled: boolean) => {
    return (
      <div className="form-group">
        <div className="form-label--tooltip">
          <label htmlFor="weekday">Day</label>
          <DashboardTooltip
            id="weekday"
            placement="left"
            contentText={<div>Select day of the week.</div>}
          />
        </div>
        <Select
          options={weekdays}
          value={weekdays.find(o => o.value === values.backupConfigurationControls.frequency)}
          defaultValue={{ label: 'Monday', value: '0 0 * * 1' }}
          isDisabled={isDisabled}
          onChange={({ value }) => setCronFrequency(value)}
        />
      </div>
    )
  }

  const renderBackupScheduleMonthday = (isDisabled: boolean) => {
    return (
      <div className="form-group">
        <div className="form-label--tooltip">
          <label htmlFor="monthdate">Date</label>
          <DashboardTooltip
            id="date of the month"
            placement="left"
            contentText={<div>Select date of the month.</div>}
          />
        </div>
        <Select
          options={monthdate}
          value={monthdate.find(o => o.value === values.backupConfigurationControls.frequency)}
          defaultValue={{ label: '1', value: '0 0 1 * *' }}
          isDisabled={isDisabled}
          onChange={({ value }) => setCronFrequency(value)}
        />
      </div>
    )
  }

  const renderBackupScheduleFrequency = (isDisabled: boolean) => {
    if (isDayOfMonthFieldEnabled() === true) {
      return renderBackupScheduleMonthday(isDisabled)
    }
    if (isDayOfWeekFieldEnabled() === true) {
      return renderBackupScheduleWeekday(isDisabled)
    }
  }

  const renderBackupRestoreFieldsOnCreate = () => {
    if (!backupRestorePlanPermission || !backupRestoreRolePermission) return <></>
    return (
      <div>
        {!showBackupSettingsOnly && <hr className="margin-top-4 margin-bottom-3" />}
        <div className="form-group">
          <label>Backup Status</label>
          <div className="form-checkbox">
            <label htmlFor="backupEnabled">
              <Switch
                checked={values.backupConfigurationControls.enabled || false}
                id="backupEnabled"
                name="backupConfigurationControls.enabled"
                onChange={handleChange}
              />
              {values.backupConfigurationControls.enabled ? 'Enabled' : 'Disabled'}
            </label>
          </div>
        </div>
        {values.backupConfigurationControls.enabled && renderBackupScheduleCategory(false)}
        {values.backupConfigurationControls.enabled && renderBackupScheduleFrequency(false)}
        {values.backupConfigurationControls.enabled && (
          <div className="form-group">
            <div className="form-label--tooltip">
              <label htmlFor="backupRetention">Retention</label>
              <DashboardTooltip
                id="backupRetention"
                placement="left"
                contentText={
                  <div>
                    This determines how long any given snapshot is retained until automatically
                    deleted.
                  </div>
                }
              />
            </div>
            <Select
              options={retentionOptions}
              defaultValue={{ label: '1 month', value: '30' }}
              value={retentionOptions.find(
                o => o.value === values.backupConfigurationControls.ttl_days + ''
              )}
              onChange={({ value }) =>
                setFieldValue('backupConfigurationControls.ttl_days', parseInt(value))
              }
            />
          </div>
        )}
      </div>
    )
  }

  const renderBackupRestoreFieldsOnEdit = () => {
    return (
      <div>
        {!showBackupSettingsOnly && <hr className="margin-top-4 margin-bottom-3" />}
        {!backupRestorePlanPermission && (
          <InfoBox
            body={
              <div>
                This feature is only available to users on the following plans:{' '}
                {acceptablePlans.map(plan => capitalize(plan)).join(', ') + '. '}
                <a href="/settings/billing">
                  <u>Upgrade plan</u>
                </a>
              </div>
            }
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )}
        {backupRestorePlanPermission && !backupRestoreRolePermission && (
          <InfoBox
            body="You have insufficient privileges to update backup setting for this database. Please contact your admin for more details."
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )}
        <div className="form-group">
          <label>Backup Status</label>
          <div className="form-checkbox">
            <label htmlFor="backupEnabled">
              <Switch
                checked={values.backupConfigurationControls.enabled || false}
                id="backupEnabled"
                name="backupConfigurationControls.enabled"
                onChange={handleChange}
                disabled={!backupRestoreRolePermission || !backupRestorePlanPermission}
              />
              {values.backupConfigurationControls.enabled ? 'Enabled' : 'Disabled'}
            </label>
          </div>
        </div>
        {backupRestorePlanPermission && (
          <div>
            {values.backupConfigurationControls.enabled &&
              renderBackupScheduleCategory(!backupRestoreRolePermission)}
            {values.backupConfigurationControls.enabled &&
              renderBackupScheduleFrequency(!backupRestoreRolePermission)}
            {(values.backupConfigurationControls.enabled || doSnapshotsExist) && (
              <div className="form-group">
                <div className="form-label--tooltip">
                  <label htmlFor="backupRetention">Retention</label>
                  <DashboardTooltip
                    id="backupRetention"
                    placement="left"
                    contentText={
                      <div>
                        This determines how long any given snapshot is retained until automatically
                        deleted.
                      </div>
                    }
                  />
                </div>
                <Select
                  options={retentionOptions}
                  defaultValue={{ label: '1 month', value: '30' }}
                  value={retentionOptions.find(
                    o => o.value === values.backupConfigurationControls.ttl_days + ''
                  )}
                  onChange={({ value }) =>
                    setFieldValue('backupConfigurationControls.ttl_days', parseInt(value))
                  }
                  disabled={!backupRestoreRolePermission}
                />
              </div>
            )}
            <div className="form-group">
              {doSnapshotsExist && <label>Snapshots</label>}
              <SnapshotTable
                region={region}
                dbPath={databasePath}
                onCopy={onCopy}
                onRestore={onRestore}
                columns={['date', 'actions']}
                resetToFirstPage={resetSnapshotPage}
              />
            </div>
          </div>
        )}
      </div>
    )
  }

  return (
    <form className="database-form" onSubmit={handleSubmit}>
      <div className="container container--xsmall">
        {!showBackupSettingsOnly && (
          <>
            <div className="form-group">
              <label htmlFor="name">{tx('database.form.name')}</label>
              <input
                id="name"
                type="text"
                name="name"
                required={!isRequestingCustom}
                disabled={isRequestingCustom}
                autoFocus
                value={values.name}
                onChange={handleChange}
              />
              <span className="database-form-hint">Cannot contain spaces</span>
            </div>
            <div className={cn('form-group', showSelectError && 'error')}>
              <div className="form-label--tooltip">
                <label>Region Group*</label>
                <DashboardTooltip
                  id="regiongroupid"
                  placement="left"
                  contentText={
                    <div>
                      Region groups determines where your data resides to better align with your
                      data locality and latency needs. See the pricing below and on our{' '}
                      <a
                        className="link-with-icon in-popover"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://fauna.com/pricing"
                      >
                        pricing page. <Icon name="external-link-alt" />
                      </a>
                    </div>
                  }
                />
              </div>
              <RegionSelect
                isDisabled={isRegionSelectDisabled}
                options={options}
                value={values.regionGroup}
                onChange={selected => setFieldValue('regionGroup', selected)}
              />
              <span className="database-form-hint">
                {showSelectError && 'This field is required'}
              </span>
              {isRequestingCustom && (
                <span className="database-form-hint">
                  To learn more about our pricing,{' '}
                  <a
                    className="link-with-icon in-popover"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://fauna.com/pricing"
                  >
                    see our pricing page. <Icon name="external-link-alt" />
                  </a>
                </span>
              )}
            </div>
            {!isRequestingCustom && isCreate && (
              <div className="form-group">
                <div className="form-checkbox">
                  <label htmlFor="demoDataIsEnabled">
                    <input
                      disabled={false}
                      checked={values.demoDataIsEnabled}
                      type="checkbox"
                      id="demoDataIsEnabled"
                      name="demoDataIsEnabled"
                      onChange={handleChange}
                    />
                    {tx('database.demo.label')}
                  </label>
                  <DashboardTooltip
                    id="demodataid"
                    placement="left"
                    contentText={<div>{tx('database.demo.info')}</div>}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {!isRequestingCustom && isEdit && renderBackupRestoreFieldsOnEdit()}
        {!isRequestingCustom && isCreate && renderBackupRestoreFieldsOnCreate()}
      </div>
      <div className="container container--xsmall">
        <div className="form-actions">
          <Button onClick={onCancel} color="secondary">
            {tx('actions.cancel')}
          </Button>
          <Button type="submit" color="success" loading={loading}>
            {tx(
              `database.form.${isRequestingCustom ? 'submitRequest' : isEdit ? 'save' : 'create'}`
            )}
          </Button>
        </div>
        {onDeleteClick && !showBackupSettingsOnly && (
          <div className="container container--xsmall">
            <hr className="margin-top-4 margin-bottom-3" />
            <div className="button-group">
              <IconButton
                color="muted"
                onClick={onDeleteClick}
                label={tx('actions.remove')}
                icon="trash"
              />
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

DatabaseForm.defaultProps = {
  loading: false
}

import { cache } from 'swr'
import { Region } from './session/regions'
import { RegisterParams, register } from '../api/auth-service/register'
import { Session } from './session'
import SessionCookie from './session/cookie'
import * as realCookie from '../cookies/sessionCookie'
import * as sltcookie from '../cookies/shortLivedTokenCookie'
import strategies, { Strategy } from './strategies'
import { Params as EmailAndPasswordParams } from '../api/auth-service/login'
import { Params as OAuthParams } from '../api/auth-service/oauth'
import {
  callForgotPassword,
  callResetPassword,
  callValidateResetToken,
  ForgotPasswordParams,
  ResetPasswordParams,
  ValidateResetTokenParams
} from '../api/auth-service/forgotPassword'
import logoutSession from '../api/auth-service/logout'

export type LoginResponse = {
  data: Session
  strategy: string
}

export async function login<Params>(
  strategy: Strategy<Params>,
  params: Params
): Promise<LoginResponse> {
  const response = await strategy(params)
  SessionCookie.save(response)
  return response
}

export function logout(): void {
  // This is a short-term solution to resolve a data persistence bug.
  // A better solution will persist the webshell state securely on customer account.
  // https://faunadb.atlassian.net/browse/FE-1394
  const webshellDataKey = Object.keys({ ...window.localStorage }).find(key =>
    key.includes('webshellState')
  )
  webshellDataKey && window.localStorage.removeItem(webshellDataKey)

  const ffDataKey = Object.keys({ ...window.localStorage }).find(key => key.includes('features'))
  ffDataKey && window.localStorage.removeItem(ffDataKey)

  // delete session key for user in FaunaDB, don't need to wait for async request to finish
  //  before removing their session cookie
  logoutSession()

  Region.clear()
  SessionCookie.remove()
  sltcookie.remove()
  cache.clear()
}

export async function loginWithEmailAndPassword(params: EmailAndPasswordParams) {
  return login(strategies.emailAndPassword, params)
}

export async function loginWithOAuth(params: OAuthParams) {
  return login(strategies.oauth, params)
}

export async function registerAndLogin(credentials: RegisterParams) {
  await register(credentials)
  return await loginWithEmailAndPassword(credentials)
}

export async function forgotPassword(params: ForgotPasswordParams) {
  return await callForgotPassword(params)
}

export async function resetPassword(params: ResetPasswordParams) {
  return await callResetPassword(params)
}

export async function validateResetToken(params: ValidateResetTokenParams) {
  return await callValidateResetToken(params)
}

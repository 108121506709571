import React, { FC, FormEvent, useState, useRef } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { tx } from '../../../modules/translate'
import { ConsoleDrawer } from '../../shared/ConsoleDrawer'
import Button from '../../shared/Button'
import { showAlert } from '../../../modules/alert/actions'
import { getErrorMessage } from '../../../utils/error'
import { useForm } from '../../../modules/form'
import { createAccountKey, CreateAccountKeyOutput } from '../../../modules/api/frontdoor/keys'
import { nowUTC, addDays, formatDate } from '../../../utils/date'
import usePermissions from '../../../config/usePermissions'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import { SessionCookieContents } from '../../../modules/cookies/sessionStorage'
import SessionCookie from '../../../modules/auth/session/cookie'

export interface AccountKeysCreateDrawerProps {
  show: boolean
  onClose: () => void
  onSuccess: (response: CreateAccountKeyOutput) => void
}

type FormValues = {
  name: string
  ttl?: string
}

const initialValues = {
  name: '',
  ttl: ''
}

const initialErrors = {
  name: '',
  ttl: ''
}

const TOKEN_NAME_REGEX = /^[\w-]{1,64}$/

export const AccountKeysCreateDrawer: FC<AccountKeysCreateDrawerProps> = ({
  show,
  onClose,
  onSuccess
}) => {
  const dispatch = useDispatch()
  const keyTTLRef = useRef(null)
  const keyNameRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState(initialErrors)
  const { formValues, setFormValue, setFormValues } = useForm<FormValues>(initialValues)

  const {
    rolePermission: roleWrite,
    planPermission: planWrite,
    acceptableRoles: acceptableWriteRoles
  } = usePermissions(PermissionDomain.API_TOKEN, PermissionLevel.write)
  const hasWritePermissions = roleWrite && planWrite

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptableWritePlans: string[]
  if (v2Released) {
    acceptableWritePlans = ['Trial', 'Pro', 'Enterprise']
  } else {
    acceptableWritePlans = ['team', 'business']
  }

  const onLocalClose = () => {
    onClose()
    resetForm()
  }

  const resetForm = () => {
    setFormValues(initialValues)
    setFormErrors(initialErrors)
    keyTTLRef.current.value = initialValues.ttl
    keyNameRef.current.value = initialValues.name
  }

  const validateFormValues = () => {
    // Key name
    if (!formValues.name) {
      setFormErrors(prev => {
        return { ...prev, name: 'Name is a required field' }
      })
      return false
    }
    if (!TOKEN_NAME_REGEX.test(formValues.name)) {
      setFormErrors(prev => {
        return {
          ...prev,
          name: "Name must be between 1 and 64 characters containing letters, numbers, '_', or '-'"
        }
      })
      return false
    }

    // Key TTL
    if (!formValues.ttl) return true
    if (!moment(formValues.ttl).isValid()) {
      setFormErrors(prev => {
        return { ...prev, ttl: 'Invalid TTL specified' }
      })
      return false
    }
    if (moment(formValues.ttl).isBefore(nowUTC())) {
      setFormErrors(prev => {
        return { ...prev, ttl: 'TTL must be in the future' }
      })
      return false
    }
    return true
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    setLoading(true)

    if (!validateFormValues()) {
      setLoading(false)
      return
    }

    await createAccountKey(formValues)
      .then(response => {
        dispatch(showAlert('Key created successfully!', 'success'))
        onSuccess(response)
      })
      .catch(error => {
        dispatch(showAlert(getErrorMessage(error), 'error'))
      })
      .finally(() => {
        onLocalClose()
        setLoading(false)
      })
  }

  const renderWritePermissionsDialog = () => {
    if (!planWrite) {
      return (
        <InfoBox
          body={
            <div>
              This feature is only available to users on the following plans:{' '}
              {acceptableWritePlans.map(plan => capitalize(plan)).join(', ') + '. '}
              <a href="/settings/billing">
                <u>Upgrade plan</u>
              </a>
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )
    } else if (!roleWrite) {
      return (
        <InfoBox
          body={
            <div>
              You must have one of the following roles to use this feature:{' '}
              {acceptableWriteRoles.map(role => capitalize(role)).join(', ')}. Please contact your
              Team Admin for more details.
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )
    }
  }

  return (
    <ConsoleDrawer open={show} onClose={onLocalClose} header={tx('accountKeys.actions.new')}>
      <div className="padding-y-2 database-form">
        <form data-testid="create-auth-key-form" onSubmit={handleSubmit}>
          <div className="form-group error">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              type="text"
              id="key_name"
              name="key_name"
              ref={keyNameRef}
              disabled={!hasWritePermissions}
              onChange={e => {
                setFormErrors(prev => {
                  return { ...prev, name: '' }
                })
                setFormValue('name', e.target.value)
              }}
              required
              autoFocus
            />
            {formErrors.name && <span className="database-form-hint">{formErrors.name}</span>}
          </div>
          <div className={`form-group ${formErrors.ttl ? 'error' : ''}`}>
            <label htmlFor="ttl">TTL (days)</label>
            <input
              id="ttl"
              name="ttl"
              type="number"
              ref={keyTTLRef}
              disabled={!hasWritePermissions}
              onChange={e => {
                setFormErrors(prev => {
                  return { ...prev, ttl: '' }
                })

                const inputValue = e.target.value
                if (!inputValue) {
                  setFormValue('ttl', '')
                  return
                }

                try {
                  setFormValue('ttl', addDays(nowUTC(), parseInt(inputValue)))
                } catch {
                  setFormErrors(prev => {
                    return { ...prev, ttl: 'Invalid TTL specified' }
                  })
                }
              }}
              autoFocus
            />
            {formErrors.ttl && <span className="database-form-hint">{formErrors.ttl}</span>}
            {formValues.ttl && !formErrors.ttl && (
              <span className="database-form-hint">
                This key will expire on {formatDate(formValues.ttl)} UTC
              </span>
            )}
          </div>
          {!hasWritePermissions && (
            <div className="form-group">{renderWritePermissionsDialog()}</div>
          )}
          <div className="form-actions">
            <Button onClick={onLocalClose} color="secondary">
              {tx('actions.cancel')}
            </Button>
            <Button
              type="submit"
              color="success"
              loading={loading}
              disabled={!hasWritePermissions}
              id="account-key-form-submit-button"
            >
              {tx('actions.create')}
            </Button>
          </div>
        </form>
      </div>
    </ConsoleDrawer>
  )
}

import cn from 'classnames'
import React, { ButtonHTMLAttributes, useContext } from 'react'
import ga from 'react-ga'
import { Link, useRouteMatch, withRouter } from 'react-router-dom'
// To have this working we need to add all project files on includes.
//@ts-ignore
import { ReactComponent as LogoIcon } from '../../assets/images/fauna-icon.svg'
import routes from '../../config/routes'
import useBillingInfo from '../../modules/api/auth-service/billingInfo'
import { MemberRole } from '../../modules/api/auth-service/members'
import * as auth from '../../modules/auth'
import { useLocalStorage } from '../../modules/localStorage'
import { tx } from '../../modules/translate'
import Button from '../shared/Button'
import Icon from '../shared/Icon'
import Modal from '../shared/ModalConfirm'
import SimpleDropdown, { SimpleDropdownItem } from '../shared/SimpleDropdown'
import AlertContainer from './AlertContainer'
import SessionCookie from '../../modules/auth/session/cookie'
import usePermissions from '../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import BetaAnnouncement from '../shared/BetaAnnouncement'
import { usagePlans } from '../pages/settings/Billing'
import { UserIdentityContext } from '../../global-state/UserIdentityContext'

type UpgradeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

// https://docs.netlify.com/site-deploys/split-testing/#expose-branch-information-in-your-site
const { BRANCH: GIT_BRANCH_NAME } = process.env

const UpgradeButton: React.FC<UpgradeButtonProps> = ({ className, ...props }) => (
  <button {...props} className={cn('upgrade-btn', className)}>
    <LogoIcon className="logo" />
    UPGRADE
  </button>
)

const Header: React.FC<{}> = () => {
  const session = SessionCookie.get()
  const { allowUpgrade } = useBillingInfo()
  const userIdentity = useContext(UserIdentityContext)
  const currentPlan = usagePlans[userIdentity.plan]?.name
  const [, setShowPricingBanner] = useLocalStorage('showPricingBanner')
  const isAddonMode = !!useRouteMatch(routes.addons.authorize)
  const { rolePermission } = usePermissions(PermissionDomain.BILLING, PermissionLevel.read)

  const onLogout = () => {
    auth.logout()
    setShowPricingBanner(true)

    if (isAddonMode) {
      return window.close()
    }

    window.location.replace(routes.auth.signIn.path)
  }

  const onMoveToUpgrade = () => {
    ga.event({
      category: 'Upgrade',
      action: 'Clicked upgrade on navbar',
      label: GIT_BRANCH_NAME
    })
  }

  function renderQuestionInfo() {
    return (
      <div className="info resources-link">
        <SimpleDropdown
          position="right"
          renderItems={() => (
            <>
              <a
                className="dropdown-item"
                target="_blank"
                rel="noopener noreferrer"
                href="https://community-invite.fauna.com/"
              >
                {tx('info.community')}
              </a>
              <a
                className="dropdown-item"
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.fauna.com/fauna/v4/"
              >
                {tx('info.doc')}
              </a>
              {/* TODO: Fix weird z-index bug with tooltip icons and dropdown on dashboard page */}
              <a
                className="dropdown-item"
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.fauna.com/fauna/v4/drivers/"
              >
                Drivers
              </a>
              <a
                className="dropdown-item"
                target="_blank"
                rel="noopener noreferrer"
                href={`${String(process.env.REACT_APP_WEBSITE_URL)}/blog/introducing-fauna-shell`}
              >
                {/* TODO LANG */}
                Fauna Shell
              </a>
              <Link to="/downloads" className="dropdown-item">
                {/* TODO LANG */}
                Downloads
              </Link>
            </>
          )}
        >
          <span className="fas fa-question-circle" />
        </SimpleDropdown>
      </div>
    )
  }

  return (
    <>
      <div className="header">
        <Link to="/" className={cn('home-link', isAddonMode && 'disabled')}>
          <span className="logo-wrap">
            <LogoIcon className="fauna-logo-desktop" />
          </span>
          <span className="crumbs">{session?.data?.account?.company_name || 'Fauna'}</span>
        </Link>
        <BetaAnnouncement message={'Beta'} />
        <div className="header__right">
          {currentPlan && (
            <div className="upgrade-wrapper">
              <div className="plan-type-wrapper">
                <div>plan</div>
                <div className="plan-type">{currentPlan}</div>
              </div>
              {allowUpgrade && !isAddonMode && rolePermission && (
                <Link to={routes.settings.billing.path}>
                  <UpgradeButton onClick={onMoveToUpgrade} />
                </Link>
              )}
            </div>
          )}
          {renderQuestionInfo()}
          {isAddonMode ? (
            <>
              <span className="profile-email">{session?.data?.user?.email}</span>
              <Button color="primary" onClick={onLogout}>
                {tx('auth.actions.logout')}
              </Button>
            </>
          ) : (
            <AccountDropdown onLogout={onLogout} />
          )}
        </div>
      </div>

      <AlertContainer />
      <Modal />
    </>
  )
}

const AccountDropdown = ({ onLogout }: { onLogout: () => void }) => {
  const { data: session } = SessionCookie.get()

  return (
    <div className="info" data-testid="user-settings">
      <SimpleDropdown
        position="right"
        renderItems={toggleDropdown => (
          <>
            <Link
              onClick={toggleDropdown}
              title="Personal Settings"
              to={routes.settings.profile.path}
              className="dropdown-item--profile"
            >
              <div className="dropdown-item--profile__avatar">
                <i className="fas fa-user-circle"></i>
              </div>

              <div className="dropdown-item--profile__info">
                {session.user.name && (
                  <span className="dropdown-item--profile__name">{session.user.name}</span>
                )}
                <span className="dropdown-item--profile__email">{session.user.email}</span>
              </div>
            </Link>

            {session.user.role !== MemberRole.developer && (
              <Link
                onClick={toggleDropdown}
                to={routes.settings.account.path}
                className="dropdown-item"
              >
                <Icon name="cog" />
                <span>Settings</span>
              </Link>
            )}

            <SimpleDropdownItem onClick={onLogout}>
              <i className="fas fa-sign-out-alt" data-testid="signout"></i>
              {tx('auth.actions.logout')}
            </SimpleDropdownItem>
          </>
        )}
      >
        <Icon name="user-circle" />
      </SimpleDropdown>
    </div>
  )
}

export default withRouter(Header)

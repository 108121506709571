import {
  get,
  remove,
  save,
  exists,
  mergeSessionData,
  SESSION_STORAGE_NAME
} from '../../cookies/sessionStorage'

export default {
  get,
  remove,
  save,
  exists,
  mergeSessionData,
  SESSION_STORAGE_NAME
}

import axios from 'axios'
import { rootSecret } from '../index'

export const AUTHZ_API_URL = `${process.env.REACT_APP_AUTH_URL}/auth/identity`
export type ViewControls = Record<string, Array<string>>
export type Limit = { soft?: number; hard?: number }
export type Limits = {
  db_count?: Limit
  /** history days limit */
  temporality?: Limit
  /** total team members including the owner */
  team_members?: Limit
}

export type UserIdentity = {
  view_controls: ViewControls
  role: string
  plan: string
  planSynced: boolean
  plan_display_name: string
  is_plan_deprecated: boolean
  limits: Limits
  id: string
  feature_flags: { pricing_v2: boolean }
}

export const getUserIdentity = async () => {
  return await axios
    .get<UserIdentity>(AUTHZ_API_URL, {
      withCredentials: true
    })
    .then(({ data }) => {
      return data
    })
    .catch(e => {
      throw e
    })
}

import moment from 'moment'
import { Region } from '../../modules/auth/session/regions'
import { useEffect, useLayoutEffect, useState, useCallback, useContext } from 'react'
import { whoami } from '../../modules/api/frontdoor/auth'
import { withRouter } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import routes from '../../config/routes'
import * as API from '../../modules/api'
import { MemberRole } from '../../modules/api/auth-service/members'
import { checkSession, hasLocalSession } from '../../modules/auth/session'
import SessionCookie from '../../modules/auth/session/cookie'
import getRegion from '../../modules/auth/session/regions'
import { manualEnableFeature } from '../../modules/features'
import { ALLOWED_BILLING_PATHS } from '../../modules/policies'
import { isUnavailableErrorOrTerminatedRequest } from '../../utils/error'
import { appendQueryString } from '../../utils/url'
import queryString from 'query-string'
import { useQuery } from '../../utils/useQuery'
import { usagePlans } from '../pages/settings/Billing'
import { GlobalDispatchContext, GlobalStateContext } from '../../global-state'
import { SET_USER_PLAN, SET_USER_ROLE } from '../../global-state/reducer'
import { UserIdentityContext } from '../../global-state/UserIdentityContext'

const BLOCKED_PATH_KEY = 'blocked_path'

interface BlockedPath {
  pathname: string
  search: string
  hash: string
}

const saveBlockedPath = (blockedPath: BlockedPath) => {
  if (blockedPath.pathname !== routes.auth.signIn.path)
    window.sessionStorage.setItem(BLOCKED_PATH_KEY, JSON.stringify(blockedPath))
}

const getBlockedPath = (): BlockedPath | undefined => {
  const sessionItem = window.sessionStorage.getItem(BLOCKED_PATH_KEY)
  return sessionItem ? JSON.parse(sessionItem) : null
}

const removeBlockedPath = () => {
  window.sessionStorage.removeItem(BLOCKED_PATH_KEY)
}

const mountBlockedPathURL = (blockedPath: BlockedPath, location) => {
  const pathname =
    blockedPath.pathname === routes.auth.signIn.path ? routes.home.path : blockedPath.pathname
  return (
    appendQueryString(pathname + blockedPath.search, location.search || null) + blockedPath.hash
  )
}

export function AuthProtect({ children, history, location, match }) {
  const [hasSession, setHasSession] = useState(false)
  const [verifiedPermission, setVerifiedPermission] = useState(false)
  const blockedPath = getBlockedPath()
  const shouldRender = hasSession && !blockedPath && verifiedPermission
  const query = useQuery()
  const { plan: currentPlan } = useContext(UserIdentityContext)
  const manualEnabledFeature = query.get('ff')
  const dispatch = useContext(GlobalDispatchContext)
  const globalState = useContext(GlobalStateContext)

  useLayoutEffect(() => {
    manualEnableFeature(manualEnabledFeature)
  }, [manualEnabledFeature])

  useLayoutEffect(() => {
    if (hasSession) {
      const region = getRegion(match?.params?.region)
      if (region) API.setCurrentRegion(region)
    }
  }, [hasSession, match?.params?.region])

  // Store the user's role and plan in the global context
  useEffect(() => {
    if (!hasSession) return
    const session = SessionCookie.get()
    dispatch({
      type: SET_USER_PLAN,
      payload: currentPlan
    })

    if (session?.data?.user?.role !== undefined) {
      dispatch({
        type: SET_USER_ROLE,
        payload: session.data.user.role
      })
      // FIXME: role should not be stored in the cookie, it can be manipulated. In the future,
      //  auth-service should have an endpoint to get the user's role, instead of returning it
      //  from the login endpoint
      // delete session.data.user.role
      // SessionCookie.save(session)
    }
  }, [hasSession, currentPlan, dispatch])

  // initialize datadog
  useEffect(() => {
    if (!hasSession) return
    const session = SessionCookie.get()
    const userRole = session?.data?.user?.role || globalState.userRole

    if (
      hasSession &&
      session &&
      process.env.REACT_APP_DD_APPLICATION_ID &&
      process.env.REACT_APP_DD_CLIENT_TOKEN
    ) {
      datadogRum.setUser({
        id: session.data.user.id,
        role: userRole,
        accountId: session.data.account.account_id,
        planLevel: currentPlan,
        planPrice: usagePlans[currentPlan]?.price
      })
    }
  }, [hasSession, currentPlan, globalState.userRole])

  // initialize pendo
  useEffect(() => {
    if (!hasSession) return
    const needsPendoInitializing =
      // @ts-ignore
      typeof pendo !== 'undefined' && hasSession

    const session = SessionCookie.get()

    if (needsPendoInitializing && session) {
      // @ts-ignore
      const pendoParams = {
        visitor: {
          id: session.data.user.id,
          email: session.data.user.email,
          role: session.data.user.role,
          sign_up_method: session.strategy
        },
        account: {
          id: session.data.account.account_id,
          planLevel: currentPlan,
          planPrice: usagePlans[currentPlan]?.price
        },
        guides: {
          disabled: true
        }
      }
      if (session.data.user.name) {
        pendoParams.visitor['name'] = session.data.user.name
      }
      if (session.data.account.company_name) {
        pendoParams.account['companyName'] = session.data.account.company_name
      }
      // dates must be in ISO 8601 form to send to pendo
      if (
        session.data.user.created_at &&
        moment(session.data.user.created_at, moment.ISO_8601, true).isValid()
      ) {
        pendoParams.visitor['createdAt'] = session.data.user.created_at
      }
      // @ts-ignore
      pendo.initialize(pendoParams)
    }
  }, [hasSession, currentPlan])

  // Tell google analytics that a user has signed up. Should only happen on their
  //  first session. Send user_id to Google Analytics so it can be mapped to other GA data.
  useEffect(() => {
    if (!hasSession) return
    const { first_session: firstSession } = queryString.parse(location.search)
    if (firstSession) {
      const session = SessionCookie.get()
      if (session && session.data.user.id) {
        //@ts-ignore
        window.dataLayer = window.dataLayer || []
        //@ts-ignore
        window.dataLayer.push({
          event: 'new_user',
          faunaID: session.data.user.id
        })
      }
    }
  }, [hasSession, location.search])

  /**
   * Checks if session cookie exists, else reroutes to login
   * @returns void
   */
  const verifyLocalSession = useCallback(async () => {
    try {
      checkSession()
      setHasSession(true)
      return true
    } catch (error) {
      /**
       * If Fauna is down or high latency we do nothing
       */
      if (isUnavailableErrorOrTerminatedRequest(error)) {
        return
      }
      setHasSession(false)

      /**
       * If it fails and is redirected, it can be an invalid path
       * so, we retry redirecting the user to the home page
       */
      if (location.state?.redirected) {
        history.push(routes.home.path)
        return
      }

      saveBlockedPath({
        pathname: location.pathname,
        search: location.search,
        hash: location.hash
      })

      history.push({
        pathname: routes.auth.signIn.path,
        ...(location.pathname === routes.addons.authorize.path && {
          state: {
            minimal: true
          }
        })
      })
      return false
    }
  }, [history, location.pathname, location.search, location.hash, location.state])

  /**
   * Checks if session cookie database keys are valid
   * @returns void
   */
  const verifyRemoteSession = useCallback(async () => {
    try {
      await whoami(Region.default)
    } catch (error) {
      if (error?.response?.status === 401) {
        history.push({
          pathname: routes.auth.signIn.path,
          ...(location.pathname === routes.addons.authorize.path && {
            state: {
              minimal: true
            }
          }),
          search: `?invalid-session=true`
        })
      }
    }
  }, [history, location.pathname])

  useEffect(() => {
    verifyLocalSession()
    if (hasLocalSession) {
      verifyRemoteSession()
    }
  }, [verifyLocalSession, verifyRemoteSession])

  /**
   * Runs a single time when the component is mounted. During mounting of the window and cleanup,
   * verifies the session cookie exists. This reroutes the user to login if their cookie expires
   * or is deleted, or if they log out from another window.
   */
  useEffect(() => {
    const onFocus = () => {
      return verifyLocalSession()
    }
    window.addEventListener('focus', onFocus)
    return () => {
      window.removeEventListener('focus', onFocus)
    }
  })

  /**
   * When user tried to access a private url without login
   * we try to redirect the user to the same page after login
   */
  useEffect(() => {
    if (hasSession && blockedPath) {
      removeBlockedPath()
      history.push(mountBlockedPathURL(blockedPath, location), { redirected: true })
      return
    }
  }, [blockedPath, hasSession, history, location])

  /**
   * Check user permissions
   * Billing users only have access to invoices and billing info
   */
  useEffect(() => {
    if (!hasSession) {
      return
    }

    const session = SessionCookie.get()
    const role = session.data.user.role
    const isBilling = role === MemberRole.billing
    const isAllowedBillingPath = ALLOWED_BILLING_PATHS.includes(location.pathname)

    if (!isBilling) {
      setVerifiedPermission(true)
      return
    }

    if (isAllowedBillingPath) {
      setVerifiedPermission(true)
      return
    }

    history.push(routes.settings.invoices.path)
    setVerifiedPermission(true)
  }, [hasSession, history, location.pathname])

  /** Render nothing when checks are happening */
  if (!shouldRender) return null

  return children
}

export default withRouter(AuthProtect)

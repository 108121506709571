import React, { useState } from 'react'
import routes from '../../../../config/routes'
import { tx } from '../../../../modules/translate'
import ContentHeader from '../../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../../shared/Breadcrumbs'
import InfoBox, { InfoBoxType } from '../../../shared/InfoBox'
import usePermissions from '../../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import capitalize from 'lodash/capitalize'
import useQuerylogs from '../../../../modules/api/useQueryLogs'
import Icon from '../../../shared/Icon'
import Spinner from '../../../shared/Spinner'
import Well from '../../../shared/Well'
import { QuerylogDrawer } from '../../databases/QuerylogDrawer'
import { Querylog } from '../../../../modules/api/frontdoor/queryLogs'
import QuerylogsTable, { NEW_REQUEST_POLLING_SPEED } from './QueryLogsTable'
import { SessionCookieContents } from '../../../../modules/cookies/sessionStorage'
import SessionCookie from '../../../../modules/auth/session/cookie'

export const QueryLogs: React.FC = () => {
  const DEFAULT_PAGE_SIZE = 8

  const {
    rolePermission: roleRead,
    planPermission: planRead,
    loading: loadingReadPermissions,
    acceptableRoles: acceptableReadRoles
  } = usePermissions(PermissionDomain.QUERYLOG, PermissionLevel.read)

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptableReadPlans: string[]
  if (v2Released) {
    acceptableReadPlans = ['Trial', 'Pro', 'Enterprise']
  } else {
    acceptableReadPlans = ['team', 'business']
  }

  const hasReadPermissions = roleRead && planRead
  const queryLogs = useQuerylogs(DEFAULT_PAGE_SIZE)
  const [showCreateDrawer, setShowCreateDrawer] = useState(false)
  // The create query log drawer lives in this parent, and it must add to the
  //  inFlightRequests which will be used by the table. The table will in turn
  //  update this state variables within its async cells when polling happens
  const [inFlightRequests, setInFlightRequests] = useState(new Map<string, number>())

  const renderReadPermissionsDialog = () => {
    if (loadingReadPermissions) {
      return (
        <div className="loader">
          <Spinner />
        </div>
      )
    } else {
      if (!planRead) {
        return (
          <InfoBox
            body={
              <div>
                This feature is only available to users on the following plans:{' '}
                {acceptableReadPlans.map(plan => capitalize(plan)).join(', ') + '. '}
                <a href="/settings/billing">
                  <u>Upgrade plan</u>
                </a>
              </div>
            }
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )
      } else if (!roleRead) {
        return (
          <InfoBox
            body={
              <div>
                You must have one of the following roles to use this feature:{' '}
                {acceptableReadRoles.map(plan => capitalize(plan)).join(', ')}. Please contact your
                Team Admin for more details.
              </div>
            }
            iconName="info-circle"
            type={InfoBoxType.primary}
          />
        )
      }
    }
  }

  return (
    <>
      <QuerylogDrawer
        show={showCreateDrawer}
        onClose={() => {
          setShowCreateDrawer(false)
        }}
        onSuccess={(bundle: Querylog) => {
          queryLogs.mutate()
          queryLogs.goToFirst()
          setInFlightRequests(ids => {
            ids.set(bundle.request_id, NEW_REQUEST_POLLING_SPEED)
            return new Map(ids)
          })
        }}
      />
      <ContentHeader
        divider
        className="justify-content-space-between"
        actions={
          <span
            className="btn btn-success"
            onClick={() => {
              setShowCreateDrawer(true)
            }}
          >
            {tx('logs.actions.new')}
          </span>
        }
      >
        {tx('logs.title')}
      </ContentHeader>
      <div className="padding-y-3" />
      {!hasReadPermissions ? (
        renderReadPermissionsDialog()
      ) : (
        <>
          {queryLogs.isInitialLoading ? (
            <div className="loader">
              <Spinner />
            </div>
          ) : (
            <>
              {queryLogs.list.length === 0 ? (
                <div className="card">
                  <Well
                    shadow
                    title={tx('logs.empty')}
                    icon={<Icon name="chart-bar" />}
                    action={
                      <span
                        className="btn btn-success"
                        onClick={() => {
                          setShowCreateDrawer(true)
                        }}
                      >
                        {tx('logs.actions.export')}
                      </span>
                    }
                    description={
                      <div className="container container--small">
                        <p className="padding-y-3">{tx('logs.description')}</p>
                      </div>
                    }
                  />
                </div>
              ) : (
                <QuerylogsTable
                  data={queryLogs}
                  inFlightRequests={inFlightRequests}
                  setInFlightRequests={setInFlightRequests}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: tx('actions.settings'),
    path: routes.settings.profile.path
  },
  {
    label: tx('logs.title'),
    path: routes.settings.backups.path
  }
])(QueryLogs)

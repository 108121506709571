import React, { useState } from 'react'
import routes from '../../../config/routes'
import * as API from '../../../modules/api'
import { Snapshot } from '../../../modules/api/frontdoor/backup'
import getRegion from '../../../modules/auth/session/regions'
import { databaseIdFromPath } from '../../../modules/databaseResource'
import { tx } from '../../../modules/translate'
import ContentHeader from '../../layouts/ContentHeader'
import { withBreadcrumbs } from '../../shared/Breadcrumbs'
import DatabasesTable from '../../shared/DatabasesTable'
import Dropdown from '../../shared/Dropdown'
import SnapshotsTable from '../../shared/SnapshotsTable'
import { useDrawer } from '../databases/useDrawer'
import DatabaseSettingsDrawer, { Stage } from './DatabaseSettingsDrawer'
import InfoBox, { InfoBoxType } from '../../shared/InfoBox'
import usePermissions from '../../../config/usePermissions'
import { PermissionDomain, PermissionLevel } from 'dx-feature-permissions'
import capitalize from 'lodash/capitalize'
import { SessionCookieContents } from '../../../modules/cookies/sessionStorage'
import SessionCookie from '../../../modules/auth/session/cookie'

export const BackupSettings: React.FC = () => {
  const { planPermission: showBackupRestore } = usePermissions(
    PermissionDomain.BACKUP_RESTORE,
    PermissionLevel.read
  )

  const session: SessionCookieContents = SessionCookie.get()
  const v2Released = session.data?.feature_flags?.pricing_v2
  let acceptablePlans: string[]
  if (v2Released) {
    acceptablePlans = ['Startup', 'Trial', 'Pro', 'Enterprise']
  } else {
    acceptablePlans = ['team', 'business']
  }

  const [dbSettingsParams, setDbSettingsParams] = useState<{
    dbPath: string
    region: string
    snapshot?: Snapshot
    dbName?: string
  }>({ dbPath: '', region: '', snapshot: null, dbName: null })
  const [dbSettingsStage, setDbSettingsStage] = useState(Stage.SETTINGS)
  const [dropdownValue, setDropdownValue] = useState('activeDatabases')

  const dbSettingsDrawer = useDrawer('dbSettings')

  const onCopy = (snapshot: Snapshot, dbPath: string, regionPrefix: string) => {
    setDbSettingsStage(Stage.COPY)
    setDbSettingsParams({
      dbPath: '',
      snapshot: snapshot,
      region: regionPrefix,
      dbName: databaseIdFromPath(dbPath)
    })
    dbSettingsDrawer.onSwitch()
  }

  const onOpenSettings = (event: React.MouseEvent, path: string, regionPrefix: string) => {
    event.stopPropagation()
    setDbSettingsParams({ dbPath: path, region: regionPrefix })
    setDbSettingsStage(Stage.SETTINGS)
    API.setCurrentRegion(getRegion(regionPrefix))
    dbSettingsDrawer.onSwitch()
  }

  return (
    <>
      <ContentHeader divider>{tx('backup.titlePlural')}</ContentHeader>
      <div className="padding-y-3" />
      {!showBackupRestore && (
        <InfoBox
          body={
            <div>
              This feature is only available to users on the following plans:{' '}
              {acceptablePlans.map(plan => capitalize(plan)).join(', ') + '. '}
              <a href="/settings/billing">
                <u>Upgrade plan</u>
              </a>
            </div>
          }
          iconName="info-circle"
          type={InfoBoxType.primary}
        />
      )}
      {showBackupRestore && (
        <section className="card card-flex">
          <div className="card-header">
            <Dropdown
              items={[
                { value: 'snapshots', label: 'Snapshots' },
                { value: 'activeDatabases', label: 'Databases' }
              ]}
              value={dropdownValue}
              onChange={(value: string) => setDropdownValue(value)}
            />
          </div>
          <div>
            {dropdownValue === 'snapshots' && (
              <SnapshotsTable
                pageSize={10}
                onCopy={onCopy}
                withBackupConfiguration={true}
                columns={['name', 'region', 'date', 'expires', 'size', 'actions']}
              />
            )}
            {dropdownValue === 'activeDatabases' && (
              <DatabasesTable onOpenSettings={onOpenSettings} />
            )}
          </div>

          <DatabaseSettingsDrawer
            open={dbSettingsDrawer.show}
            initialStage={dbSettingsStage}
            dbSettingsParams={dbSettingsParams}
            onClose={dbSettingsDrawer.onSwitch}
            showBackupSettingsOnly={true}
            shouldRedirectOnSubmit={false}
          />
        </section>
      )}
    </>
  )
}

export default withBreadcrumbs(() => [
  {
    label: tx('actions.settings'),
    path: routes.settings.profile.path
  },
  {
    label: tx('backup.titlePlural'),
    path: routes.settings.backups.path
  }
])(BackupSettings)
